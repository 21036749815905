/* eslint-disable import/no-extraneous-dependencies */
// Core Packages
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  concat,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";

// Utils
import { getItem } from "../services/storage";
import { AUTH_TOKEN, PLATFORM } from "../utils/constants";
import { generateIdToken } from "../services/auth";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

const authMiddleware = new ApolloLink((operation, forward) => {
  const authToken = getItem(AUTH_TOKEN);

  operation.setContext({
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : "",
      Platform: PLATFORM,
    },
  });
  return forward(operation);
});

const link = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => {
      if (error.statusCode === 401) {
        generateIdToken();
        return true;
      }
      return false;
    },
  },
});

const client = new ApolloClient({
  link: link.concat(concat(authMiddleware, httpLink)),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          customer: {
            merge: true,
          },
        },
      },
    },
  }),
});

export default client;
